// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2001-js": () => import("./../../../src/pages/2001.js" /* webpackChunkName: "component---src-pages-2001-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5000-semiautomatica-js": () => import("./../../../src/pages/5000semiautomatica.js" /* webpackChunkName: "component---src-pages-5000-semiautomatica-js" */),
  "component---src-pages-5000-semiautomaticabobina-js": () => import("./../../../src/pages/5000semiautomaticabobina.js" /* webpackChunkName: "component---src-pages-5000-semiautomaticabobina-js" */),
  "component---src-pages-5000-semiautomaticamanual-js": () => import("./../../../src/pages/5000semiautomaticamanual.js" /* webpackChunkName: "component---src-pages-5000-semiautomaticamanual-js" */),
  "component---src-pages-aplicaciones-js": () => import("./../../../src/pages/Aplicaciones.js" /* webpackChunkName: "component---src-pages-aplicaciones-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/Contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-demos-js": () => import("./../../../src/pages/Demos.js" /* webpackChunkName: "component---src-pages-demos-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/Empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-en-2001-js": () => import("./../../../src/pages/en_2001.js" /* webpackChunkName: "component---src-pages-en-2001-js" */),
  "component---src-pages-en-5000-semiautomatica-js": () => import("./../../../src/pages/en_5000semiautomatica.js" /* webpackChunkName: "component---src-pages-en-5000-semiautomatica-js" */),
  "component---src-pages-en-5000-semiautomaticabobina-js": () => import("./../../../src/pages/en_5000semiautomaticabobina.js" /* webpackChunkName: "component---src-pages-en-5000-semiautomaticabobina-js" */),
  "component---src-pages-en-5000-semiautomaticamanual-js": () => import("./../../../src/pages/en_5000semiautomaticamanual.js" /* webpackChunkName: "component---src-pages-en-5000-semiautomaticamanual-js" */),
  "component---src-pages-en-aplicaciones-js": () => import("./../../../src/pages/en_aplicaciones.js" /* webpackChunkName: "component---src-pages-en-aplicaciones-js" */),
  "component---src-pages-en-contacto-js": () => import("./../../../src/pages/en_contacto.js" /* webpackChunkName: "component---src-pages-en-contacto-js" */),
  "component---src-pages-en-demos-js": () => import("./../../../src/pages/en_demos.js" /* webpackChunkName: "component---src-pages-en-demos-js" */),
  "component---src-pages-en-empresa-js": () => import("./../../../src/pages/en_empresa.js" /* webpackChunkName: "component---src-pages-en-empresa-js" */),
  "component---src-pages-en-grabados-js": () => import("./../../../src/pages/en_grabados.js" /* webpackChunkName: "component---src-pages-en-grabados-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en_index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-maquinas-js": () => import("./../../../src/pages/en_maquinas.js" /* webpackChunkName: "component---src-pages-en-maquinas-js" */),
  "component---src-pages-en-peliculas-js": () => import("./../../../src/pages/en_peliculas.js" /* webpackChunkName: "component---src-pages-en-peliculas-js" */),
  "component---src-pages-en-revolver-5080-js": () => import("./../../../src/pages/en_revolver5080.js" /* webpackChunkName: "component---src-pages-en-revolver-5080-js" */),
  "component---src-pages-grabados-js": () => import("./../../../src/pages/Grabados.js" /* webpackChunkName: "component---src-pages-grabados-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maquinas-js": () => import("./../../../src/pages/Maquinas.js" /* webpackChunkName: "component---src-pages-maquinas-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-page-3-js": () => import("./../../../src/pages/page-3.js" /* webpackChunkName: "component---src-pages-page-3-js" */),
  "component---src-pages-peliculas-js": () => import("./../../../src/pages/Peliculas.js" /* webpackChunkName: "component---src-pages-peliculas-js" */),
  "component---src-pages-revolver-5080-js": () => import("./../../../src/pages/revolver5080.js" /* webpackChunkName: "component---src-pages-revolver-5080-js" */)
}

